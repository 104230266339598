<template lang="pug">
.container.growToPage
  .loader-box(v-if="loading")
    p.has-text-weight-bold Loading all loot history...
    .loader

  .columns(v-else)
    .column.is-3
      b-menu
        b-menu-list(label="All Loot")
          b-menu-item(@click="changeBoss('all')" label="All items" :active="true")
          b-menu-item(@click="changeBoss('mine')" label="Items I won")
        b-menu-list(v-for="lz in $user.loot.zones" :label="lz.name")
          b-menu-item(v-for="b in filteredBosses(lz.id)" @click="changeBoss(b.id)" :label="b.name")


    .column.is-9
      .box(v-if="selected_boss == null")
        p.has-text-centered No loot selected
      .box(v-else)
        .level
          .level-left
            .level-item
              .title.is-4 {{selectedLoot.title}}
        hr

        b-table(:data="selectedLoot.items" :bordered="true")
          b-table-column(label="Date" v-slot="props")
            span {{toDate(props.row.created)}}
          b-table-column(label="Item" v-slot="props")
            a.q4.wowhead-link(:href="`https://classic.wowhead.com/item=${props.row.item}`" target="_blank") [{{itemsById[props.row.item].name}}]
          b-table-column(label="Winner" v-slot="props")
            span {{props.row.nickname == null ? "Disenchant" : props.row.nickname}}
          b-table-column(label="Note" v-slot="props")
            span {{props.row.note}}


</template>

<style scoped>
.loader-box {
    display: grid;
    place-items: center;
}

.loader {
    width: 50px;
    height: 50px;
}

.pad-right {
    padding-right: 0.5rem;
}

</style>

<script>
export default {
    name: "LootHistory",
    data () {
        return {
            loading: true,
            selected_boss: 'all',
            awarded_loot: [],
        }
    },
    props: ['memberID'],
    mounted: async function () {
        // Load standings
        if (this.loading == true) {
            await this.loadStandings();
        }
    },
    methods: {
        loadStandings: async function () {
            this.loading = true;
            this.awarded_loot = await this.$api.generic('get', `/team/${this.$user.active_raid_team.raid_team}/history`);
            console.log(this.awarded_loot);

            this.loading = false;
        },
        changeBoss: function (i) {
            this.selected_boss = i;
        },
        filteredBosses: function (lz) {
            return this.$user.loot.bosses.filter(b => b.zone == lz);
        },
        itemSubloot: function (i) {
            return this.$user.loot.subloot.filter(s => s.subloot_of == i);
        },
        toDate: function (d) {
            return (new Date(d)).toLocaleDateString();
        },
    },
    computed: {
        selectedLoot: function () {
            if (this.selected_boss == 'all') {
                return {
                    title: "All Loot",
                    items: this.awarded_loot,
                }
            } else if (this.selected_boss == 'mine') {
                return {
                    title: "LOOT YOU WON!",
                    items: this.awarded_loot.filter(x => x.member == this.memberID),
                };
            } else {
                let b = this.$user.loot.bosses.find(b => b.id == this.selected_boss);
                let items = this.$user.loot.combos.filter(x => x.boss == this.selected_boss).map(y => y.item);

                return {
                    title: `${b.name} Loot`,
                    items: this.awarded_loot.filter(x => items.includes(x.item)),
                };
            }
        },
        itemsById: function () {
            let itemMap = {};
            for (let m of this.$user.loot.items) {
                itemMap[m.id] = m;
            }
            return itemMap;
        }
    }
}
</script>
