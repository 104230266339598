<template lang="pug">
.container.growToPage
  .headpad
    h3.title.is-size-2.has-text-centered Raid Team Manager

  .loader-box(v-if="loading")
    p.has-text-weight-bold Loading your raid teams...
    .loader

  .columns(v-else)
    .column.is-3
      nav.panel
        p.panel-heading.has-text-centered Raid Teams
        a.panel-block(v-for="(rt, index) in raid_teams" @click="changeTeam(index)")
          span {{`#${rt.id} - ${rt.name}`}}
        .panel-block
          button.button.is-warning.is-fullwidth Create new Raid Team

    .column.is-9
      .box(v-if="selected_team == null")
        p.has-text-centered No raid team selected
      .box(v-else)
        .level
          .level-left
            .level-item
              .title.is-4 {{selected_team.name}}
        hr
        .field
          label.label Raid Team Name
        .field
          .control
            input.input(type="text" v-model="selected_team.name")
        .field
          label.label Notes (this is public)
        .field
          .control
            textarea.textarea(type="text" rows="8" v-model="selected_team.notes")
        .field.is-grouped.is-grouped-centered
          button.button.is-danger(@click="updateTeam" v-bind:class="updateTeamButton.classes") Update

        hr
        .field
          label.label Team Members

        .loader-box(v-if="loading_player_list")
          .loader

        table.table.is-bordered.is-fullwidth(v-else)
          thead
            tr
              th User ID
              th Character Name
              th Spec note
              th Council Member?
          tbody
            tr(v-for="p in player_list")
              td {{p.uid}}
              td {{p.nickname || '###NameNotSet'}}
              td {{p.spec}}
              td {{p.is_council_member}}
              td
                .field.is-grouped
                  .control
                    button.button.is-small.is-success(disabled) PROMOTE
                  .control
                    button.button.is-small.is-danger(disabled) KICK

        hr
        .field
          label.label Add a user
        .field.is-grouped
          .control
            input.input(type="number" v-model="add_user_field")
          .control
            button.button.is-danger(@click="addUser" :disabled="addUserButton.disabled" :classes="addUserButton.classes") ADD
        p.help This is displayed to raiders after they've made an account. It's a number.


</template>

<style scoped>
.loader-box {
    display: grid;
    place-items: center;
}

.loader {
    width: 50px;
    height: 50px;
}

.headpad {
    padding: 2rem;
}

.contentGrid {
    display: grid;
    place-items: center;
    height: 100%;
}


</style>

<script>
export default {
    name: "RaidAdmin",
    data () {
        return {
            loading: true,
            raid_teams: [],
            selected_team: null,
            updating_team: false,
            loading_player_list: true,
            player_list: [],
            add_user_field: null,
            adding_user: false,
        }
    },
    mounted: async function () {
        // Get raid team info
        await this.loadTeams();
    },
    methods: {
        loadTeams: async function () {
            // Get raid team info
            this.loading = true;
            this.selected_team = null;
            this.raid_teams = [];


            this.raid_teams = await this.$api.generic('get', '/admin/raid_teams');
            this.loading = false;
        },
        changeTeam: async function (i) {
            this.add_user_field = null;
            this.selected_team = JSON.parse(JSON.stringify(this.raid_teams[i]));
            await this.getPlayerList();
        },
        updateTeam: async function () {
            if (this.updating_team) return;
            this.updating_team = true;

            try {
                await this.$api.generic('post', `/admin/raid_team/${this.selected_team.id}`, this.selected_team);

                await this.loadTeams();

            } catch (e) {
                this.$buefy.notification.open({
                    message: `Error: ${e.message}`,
                    type: 'is-danger',
                    hasIcon: true,
                });
                this.$log.error("Failed to update team info");
                this.$log.error(e);
            }
            this.updating_team = false;
        },
        getPlayerList: async function () {
            this.loading_player_list = true;
            this.player_list = [];

            try {
                this.player_list = await this.$api.generic('get', `/admin/player_list/${this.selected_team.id}`);
                this.loading_player_list = false;
            } catch (e) {
                this.$buefy.notification.open({
                    message: `Error: ${e.message}`,
                    type: 'is-danger',
                    hasIcon: true,
                });
                this.$log.error("Failed to get raid player list");
                this.$log.error(e);
            }
        },
        addUser: async function () {
            if (this.adding_user) return;
            this.adding_user = true;

            try {
                let new_player = await this.$api.generic('post', `/admin/add_user`, {
                    team: this.selected_team.id,
                    user: this.add_user_field
                });
                this.player_list.push(new_player);
            } catch (e) {
                this.$buefy.dialog.alert({
                    title: `Error!`,
                    message: `${e.response.data.err || e.message}`,
                    type: 'is-danger',
                    hasIcon: true,
                });

                this.$log.error("Failed to add user to raid team");
                this.$log.error(JSON.stringify(e.response));
            }
            this.adding_user = false;
        }


    },
    computed: {
        updateTeamButton: function () {
            return {
                classes: {
                    'is-loading': this.updating_team
                }
            }
        },
        addUserButton: function () {
            return {
                disabled: this.add_user_field == null,
                classes: {
                    'is-loading': this.adding_user
                }
            }
        }
    }
}
</script>
