<template lang="pug">
#app
    #nav
        navigation
    #innerContent
        keep-alive
            router-view
    #foot
        foot
</template>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

html, body {
    height: 100%;
    margin: 0;
}

#app {
    display: flex;
    flex-flow: column;
    height: 100%;
}
#nav {
    flex: 0 1 auto;
}
#innerContent {
    flex: 1 1 auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
#foot {
    flex: 0 1 60px;
}

img {
    display: block;
}

.container.growToPage {
    height: 100%;
}

a.panel-block.is-active {
    border-left: 1px solid black;
}

// Minor tweak for buefy dialog
.media > .media-content {
  align-self: center;
  padding-bottom: 2px;
}

// Tweaks for wowhead links
.wowhead-link {
  font-family: Verdana,open sans,Arial;
  font-size: 13px;
}

.notices {
  padding: 4rem !important;
}

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>

<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
export default {
    name: "app",
    components: {foot: Footer, Navigation},
    data () {
        return {
            msg: 'CLASSIC-COUNCIL'
        }
    },
    mounted: async function () {
        this.$log.debug("top mounted");
        // Load raid teams here
        if (this.$user.is_authenticated) {
            await this.$api.load_raid_teams();
            await this.$api.load_loot();
        }
    },
    methods: {
        waitSecondsAsync: async function (sec) {
            return new Promise((resolve) => {
                setTimeout(resolve, sec*1000);
            });
        },
        test: async function () {
            await this.waitSecondsAsync(2);
            console.log("Finished waiting");
        },
    }
}
</script>
