import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Index from '../components/Index';
import About from '../components/About';
import NotFound from '../components/NotFound';
import Login from '../components/Login';
import Register from "../components/Register";
import Admin from "../components/Admin";
import RaidAdmin from "../components/RaidAdmin";
import Member from "../components/TeamMember";

const ifNotAuthenticated = (to, from, next) => {
    if (!Vue.user.is_authenticated) {
        next();
    } else {
        next('/dash');
    }
};

const ifAuthenticated = (to, from, next) => {
    if (Vue.user.is_authenticated) {
        next();
    } else {
        next('/');
    }
};

const ifGod = (to, from, next) => {
    if (Vue.user.info.id == 1) {
        next();
    } else {
        next('/');
    }
};

const ifAdmin = (to, from, next) => {
    if (Vue.user.info.is_admin) {
        next();
    } else {
        next('/');
    }
};

const routes = [
    {
        path: '/dash',
        component: Index,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/member/:id',
        component: Member,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/',
        component: Login,
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/register',
        component: Register,
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/admin',
        component: Admin,
        beforeEnter: ifAuthenticated, ifGod
    },
    {
        path: '/raidadmin',
        component: RaidAdmin,
        beforeEnter: ifAuthenticated, ifAdmin
    },
    {
        path: '*',
        component: NotFound,
        beforeEnter: ifAuthenticated
    }
];

export default new Router({
    mode: 'history',
    routes
});
