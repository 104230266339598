<template lang="pug">
.container.growToPage
    .contentGrid
        div
            h3.title.is-size-2.has-text-centered Welcome to Classic Council

            .loader-box(v-if="loading")
                p.has-text-weight-bold Loading your raid teams...
                .loader

            div.has-text-centered(v-else)
              div(v-if="$user.raid_teams.length == 0")
                p You are not a member of any raid teams just yet!
                p Please contact your loot council lead and ask them to add user ID <b>{{this.$user.info.id}}</b> to the team.

              div(v-else)
                .field
                  .control
                    p Choose a raid team to manage
                .field.is-grouped.is-grouped-centered
                  .control(v-for="t in $user.raid_teams")
                    router-link.button.is-outlined.is-link(:to="`/member/${t.id}#Standings`" tag="button") {{t.team_name}} - {{t.nickname || '###NameNotSet'}}

                .field
                  p.help If you need to join another raid team, ask them to add user ID <b>{{this.$user.info.id}}</b>.


</template>

<style scoped>
.loader-box {
    display: grid;
    place-items: center;
}

.loader {
    width: 50px;
    height: 50px;
}

.contentGrid {
    display: grid;
    place-items: center;
    height: 100%;
}


</style>

<script>
export default {
    name: "Index",
    data () {
        return {
            loading: true,
        }
    },
    watch: {
        '$user.loaded_teams': function () {
            this.loading = !this.$user.loaded_teams;
        }
    },
    activated: async function () {
        this.loading = !this.$user.loaded_teams;
    },
    mounted: async function () {

    },
    methods: {

    }
}
</script>
