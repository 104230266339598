<template lang="pug">
.container.growToPage
  .loader-box(v-if="loading")
    p.has-text-weight-bold Loading your rolls...
    .loader

  .columns(v-else)
    .column.is-3
      b-menu
        b-menu-list(label="All Loot")
          b-menu-item(@click="changeBoss('all')" label="All items")
          b-menu-item(@click="changeBoss('rolled')" label="Items I've rolled on" :active="true")
        b-menu-list(v-for="lz in $user.loot.zones" :label="lz.name")
          b-menu-item(v-for="b in filteredBosses(lz.id)" @click="changeBoss(b.id)" :label="b.name")


    .column.is-9
      .box(v-if="selected_boss == null")
        p.has-text-centered No raid team selected
      .box(v-else)
        .level
          .level-left
            .level-item
              .title.is-4 {{selectedLoot.title}}
        hr

        table.table.is-bordered.is-fullwidth
          thead
            tr
              th Item
              th My Roll
              th Note
              th Save
          tbody
            tr(v-for="i in selectedLoot.items")
              td
                a.q4.wowhead-link(:href="`https://classic.wowhead.com/item=${i.id}`" target="_blank") [{{i.name}}]
                .newline(v-for="sl in itemSubloot(i.id)")
                  span.wowhead-link.pad-right
                    i.fal.fa-long-arrow-right
                  a.q4.wowhead-link(:href="`https://classic.wowhead.com/item=${sl.id}`" target="_blank") [{{sl.name}}]
              td
                b-select(v-model="all_rolls[i.id].roll" size="is-small" :disabled="all_rolls[i.id].awarded == true")
                  option(value="need") Need
                  option(value="offspec") Offspec
                  option(value="pvp") PvP Set
                  option(value="pass") Pass
              td
                input.input.is-small(v-model="all_rolls[i.id].note" placeholder="Note for council" :disabled="all_rolls[i.id].awarded == true")
              td(v-if="all_rolls[i.id].awarded == true")
                p Won {{toDate(all_rolls[i.id].updated)}}
              td(v-else)
                button.button.is-small.is-link(@click="saveRoll(i.id)" :class="saveButton(i.id).classes" :disabled="saveButton(i.id).disabled") SAVE




</template>

<style scoped>
.loader-box {
    display: grid;
    place-items: center;
}

.loader {
    width: 50px;
    height: 50px;
}

.pad-right {
    padding-right: 0.5rem;
}

</style>

<script>
export default {
    name: "MemberRolls",
    data () {
        return {
            loading: true,
            selected_boss: 'rolled',
            member_rolls: [],
            all_rolls: {},
            saving_roll: false,
            saving_item_id: null,
        }
    },
    props: ['memberID'],
    mounted: async function () {
        // Load member rolls
        if (this.loading == true) {
            await this.loadRolls();
        }
    },
    methods: {
        loadRolls: async function () {
            this.loading = true;
            this.member_rolls = await this.$api.generic('get', `/member/${this.memberID}/rolls`);
            for (let o of this.$user.loot.items) {
                let memberRoll = this.member_rolls.find(r => r.item == o.id);
                if (memberRoll) {
                    this.all_rolls[o.id] = {
                        roll: memberRoll.roll,
                        note: memberRoll.note,
                        updated: memberRoll.updated,
                        awarded: memberRoll.awarded,
                    }
                } else {
                    this.all_rolls[o.id] = {
                        roll: 'pass',
                        note: null,
                        updated: null,
                        awarded: null,
                    }
                }
            }
            this.loading = false;
        },
        changeBoss: function (i) {
            this.selected_boss = i;
        },
        filteredBosses: function (lz) {
            return this.$user.loot.bosses.filter(b => b.zone == lz);
        },
        itemSubloot: function (i) {
            return this.$user.loot.subloot.filter(s => s.subloot_of == i);
        },
        toDate: function (d) {
            return (new Date(d)).toLocaleDateString();
        },
        saveRoll: async function (i) {
            if (this.saving_roll) return;
            this.saving_item_id = i;
            this.saving_roll = true;
            try {
                await this.$api.generic('post', `/member/${this.memberID}/roll`, {
                    item: i,
                    roll: this.all_rolls[i].roll,
                    note: this.all_rolls[i].note,
                });
                this.$buefy.toast.open({
                    message: "Roll saved!",
                    type: 'is-success'
                });
                this.member_rolls = this.member_rolls.filter(x => x.item != i);
                this.member_rolls.push({
                    item: i,
                    ...this.all_rolls[i]
                });
            } catch (e) {
                this.$log.error("Failed to update roll");
                this.$log.error(e);
                this.$buefy.dialog.alert({
                    title: `Error!`,
                    message: `${e.response.data.err || e.message}`,
                    type: 'is-danger',
                    hasIcon: true,
                });
            }
            this.saving_roll = false;
        },
        saveButton: function (i) {
            return {
                disabled: this.saving_roll && this.saving_item_id != i,
                classes: {
                    'is-loading': this.saving_roll && this.saving_item_id == i,
                }
            }
        }
    },
    computed: {
      selectedLoot: function () {
          if (this.selected_boss == 'all') {
              return {
                  title: "All Loot",
                  items: this.$user.loot.items.slice().sort((a, b) => a.name > b.name ? 1 : -1),
              }
          } else if (this.selected_boss == 'rolled') {
              let rolled_items = this.member_rolls.filter(i => i.awarded != true && i.roll != 'pass').map(r => r.item);
              return {
                  title: "Rolled Items",
                  items: this.$user.loot.items.slice().filter(i => rolled_items.includes(i.id)).sort((a, b) => a.name > b.name ? 1 : -1),
              };
          } else {
              let b = this.$user.loot.bosses.find(b => b.id == this.selected_boss);
              let items = this.$user.loot.combos.filter(x => x.boss == this.selected_boss).map(y => y.item);

              return {
                  title: `${b.name} Loot`,
                  items: this.$user.loot.items.slice().filter(i => items.includes(i.id)).sort((a, b) => a.name > b.name ? 1 : -1),
              };
          }
      }
    }
}
</script>
