<template lang="pug">
.container.growToPage
  h3.title.is-size-2.has-text-centered GOD MODE ADMIN

  .field
    .control
      button.button(@click="loadUserlist") Load userlist
  div(v-if="userlist.length > 0")
    .field
      .control
        .select
          select(v-model="selectedUser")
            option(v-for="u in userlist" :value="u") {{`${u.id} - ${u.username}`}}
    table.table.is-bordered(v-if="selectedUser != null")
      thead
        tr
          th ID
          th Username
          th Created
          th Is Admin?
          th Toggle
      tbody
        tr
          td {{selectedUser.id}}
          td {{selectedUser.username}}
          td {{(new Date(selectedUser.created_at)).toLocaleDateString()}}
          td {{selectedUser.is_admin}}
          td
            button.button.is-small.is-danger(@click="setAdmin(selectedUser.id, !selectedUser.is_admin)") {{selectedUser.is_admin ? 'DEMOTE' : 'PROMOTE'}}

  hr

  .field
    .subtitle Loot Stuff

  .loader-box(v-if="!$user.loaded_loot")
    .loader
    p Loot data not yet loaded

  div(v-else)
    .field
      label.label Add a new Zone
    .field.is-grouped
      .control
        input.input(v-model="newZone" placeholder="Name")
      .control
        button.button.is-danger(@click="createZone" :class="{'is-loading': creatingZone}") ADD

    .field
      label.label Add a new Boss

    .field.is-grouped
      .control
        b-select(v-model="selectedZone")
          option(v-for="lz in $user.loot.zones" :value="lz.id") {{lz.name}}
      .control
          input.input(v-model="newBoss" placeholder="Name")
      .control
          button.button.is-danger(@click="createBoss" :class="{'is-loading': creatingBoss}") ADD


    .field
        label.label Add a new Item
    .field.is-grouped
      .control
        b-select(v-model="selectedBoss")
          option(v-for="b in $user.loot.bosses" :value="b.id") {{b.name}}
      .control
          input.input(v-model="newItemID" placeholder="ItemID")
      .control
          input.input(v-model="newItemName" placeholder="Name")
      .control
          button.button.is-danger(@click="createItem" :class="{'is-loading': creatingItem}") ADD

    .field
      label.label Add extra boss to Item
    .field.is-grouped
      .control
        b-select(v-model="extraBossItem")
          option(v-for="b in sortedItems" :value="b.id") {{b.name}}
      .control
        b-select(v-model="extraBossBoss")
          option(v-for="b in $user.loot.bosses" :value="b.id") {{b.name}}
      .control
          button.button.is-danger(@click="addExtraBoss" :class="{'is-loading': addingBoss}") ADD

    .field
        label.label Add subloot to Item
    .field.is-grouped
      .control
        b-select(v-model="sublootParent")
          option(v-for="b in sortedItems" :value="b.id") {{b.name}}
      .control
        input.input(v-model="sublootID" placeholder="Subloot ItemID")
      .control
        input.input(v-model="sublootName" placeholder="Subloot Name")
      .control
        button.button.is-danger(@click="addSubloot" :class="{'is-loading': addingSubloot}") ADD


</template>

<style scoped>
.loader-box {
    display: grid;
    place-items: center;
}

.loader {
    width: 50px;
    height: 50px;
}
</style>

<script>
export default {
    name: "GodAdmin",
    data () {
        return {
            loading: true,
            userlist: [],
            selectedUser: null,
            newZone: null,
            creatingZone: false,
            selectedZone: null,
            newBoss: null,
            creatingBoss: false,
            selectedBoss: null,
            newItemID: null,
            newItemName: null,
            creatingItem: false,
            extraBossItem: null,
            extraBossBoss: null,
            addingBoss: false,
            sublootID: null,
            sublootParent: null,
            sublootName: null,
            addingSubloot: false,
        }
    },
    mounted: async function () {
        // Get raid team info
        this.loading = true;


        this.loading = false;

    },
    methods: {
        loadUserlist: async function () {
            try {
                this.userlist = await this.$api.generic('GET', '/god/userlist');
                this.selectedUser = this.userlist[0];
            } catch (e) {
                this.$log.debug("Logout failed");
                this.$log.debug(e);
            }
        },
        setAdmin: async function (user, admin) {
            try {
                await this.$api.generic('POST', '/god/setadmin', {
                    user,
                    admin,
                });
                this.selectedUser = null;
                this.userlist = [];

                await this.loadUserlist();

            } catch (e) {
                this.$log.debug("Logout failed");
                this.$log.debug(e);
            }
        },
        createZone: async function () {
            if (this.creatingZone) return;
            this.creatingZone = true;
            try {
                await this.$api.generic('post', '/god/createzone', {
                    name: this.newZone
                });
                await this.$api.load_loot();
                this.newZone = null;
            } catch (e) {
                this.$log.error("Create zone failed");
                this.$log.error(e);
            }
            this.creatingZone = false;
        },
        createBoss: async function () {
            if (this.creatingBoss) return;
            this.creatingBoss = true;
            try {
                await this.$api.generic('post', '/god/createboss', {
                    zone: this.selectedZone,
                    name: this.newBoss
                });
                await this.$api.load_loot();
                this.newBoss = null;
            } catch (e) {
                this.$log.error("Create boss failed");
                this.$log.error(e);
            }
            this.creatingBoss = false;
        },
        createItem: async function () {
            if (this.creatingItem) return;
            this.creatingItem = true;
            try {
                await this.$api.generic('post', '/god/createitem', {
                    id: this.newItemID,
                    boss: this.selectedBoss,
                    name: this.newItemName,
                });
                await this.$api.load_loot();
                this.newItemID = null;
                this.newItemName = null;
            } catch (e) {
                this.$log.error("Create item failed");
                this.$log.error(e);
            }
            this.creatingItem = false;
        },
        addExtraBoss: async function () {
            if (this.addingBoss) return;
            this.addingBoss = true;
            try {
                await this.$api.generic('post', '/god/addboss', {
                    boss: this.extraBossBoss,
                    item: this.extraBossItem,
                });
                await this.$api.load_loot();
                this.extraBossBoss = null;
                this.extraBossItem = null;
            } catch (e) {
                this.$log.error("Add extra boss failed");
                this.$log.error(e);
            }
            this.addingBoss = false;
        },
        addSubloot: async function () {
            if (this.addingSubloot) return;
            this.addingSubloot = true;
            try {
                await this.$api.generic('post', '/god/addsubloot', {
                    id: this.sublootID,
                    name: this.sublootName,
                    parent: this.sublootParent,
                });
                await this.$api.load_loot();
                this.sublootID = null;
                this.sublootName = null;
            } catch (e) {
                this.$log.error("Add subloot failed");
                this.$log.error(e);
            }
            this.addingSubloot = false;
        },
    },
    computed: {
        sortedItems: function () {
            if (this.$user.loaded_loot) {
                let i = this.$user.loot.items;
                return i.sort((a, b) => a.name > b.name ? 1 : -1);
            }
            return [];
        }
    }
}
</script>
