<template lang="pug">
.container.growToPage
  .loader-box(v-if="loading")
    p.has-text-weight-bold Loading all raider rolls...
    .loader

  .columns(v-else)
    .column.is-3
      b-menu
        b-menu-list(label="All Loot")
          b-menu-item(@click="changeBoss('all')" label="All items")
        b-menu-list(v-for="lz in $user.loot.zones" :label="lz.name")
          b-menu-item(v-for="b in filteredBosses(lz.id)" @click="changeBoss(b.id)" :label="b.name")


    .column.is-9
      .box(v-if="selected_boss == null")
        p.has-text-centered No raid team selected
      .box(v-else)
        .level
          .level-left
            .level-item
              .title.is-4 {{selectedLoot.title}}
        hr

        b-table(:data="selectedLoot.items" :bordered="true" detailed)
          b-table-column(label="Item" v-slot="props")
            a.q4.wowhead-link(:href="`https://classic.wowhead.com/item=${props.row.id}`" target="_blank") [{{props.row.name}}]
          b-table-column(label="Top Standing" v-slot="props")
            span {{all_standings[props.row.id].p1 == null ||  all_standings[props.row.id].p1 == 0 ? 'Unassigned' : membersById[all_standings[props.row.id].p1].nickname}}
          b-table-column(label="Second" v-slot="props")
            span {{all_standings[props.row.id].p2 == null ||  all_standings[props.row.id].p2 == 0  ? 'Unassigned' : membersById[all_standings[props.row.id].p2].nickname}}
          b-table-column(label="Third" v-slot="props")
            span {{all_standings[props.row.id].p3 == null ||  all_standings[props.row.id].p3 == 0  ? 'Unassigned' : membersById[all_standings[props.row.id].p3].nickname}}
          b-table-column(label="Note" v-slot="props")
            span {{all_standings[props.row.id].pub_note == null ? 'Not Set' : all_standings[props.row.id].pub_note}}

          b-table-column(label="Updated" v-slot="props")
            span {{ all_standings[props.row.id].updated == null ? 'Never' : toDate(all_standings[props.row.id].updated) }}

          template(slot="detail" slot-scope="props")
            .field.is-horizontal
              .field-label.is-normal
                label.label Award item:
              .field-body
                .field.has-addons
                  .control
                    .select
                      select(v-model="all_awards[props.row.id].member")
                        option(v-if="all_standings[props.row.id].p1 != null && all_standings[props.row.id].p1 != '0'" :value="all_standings[props.row.id].p1") #1 - {{membersById[all_standings[props.row.id].p1].nickname}}
                        option(v-if="all_standings[props.row.id].p2 != null && all_standings[props.row.id].p2 != '0'" :value="all_standings[props.row.id].p2") #2 - {{membersById[all_standings[props.row.id].p2].nickname}}
                        option(v-if="all_standings[props.row.id].p3 != null && all_standings[props.row.id].p3 != '0'" :value="all_standings[props.row.id].p3") #3 - {{membersById[all_standings[props.row.id].p3].nickname}}
                        option(value="0") Disenchant
                  .control.is-expanded
                    input.input.is-fullwidth(placeholder="Award note" v-model="all_awards[props.row.id].note")
                  .control
                    button.button.is-danger(@click="awardItem(props.row.id)" :disabled="awardButton(props.row.id).disabled" :class="awardButton(props.row.id).classes") AWARD


            hr
            .columns
              .column.is-6
                .field
                  label.label Public Notes
                .field
                  .control
                    textarea.textarea(v-model="all_standings[props.row.id].pub_note")

              .column.is-6
                .field
                  label.label Private Notes
                .field
                  .control
                    textarea.textarea(v-model="all_standings[props.row.id].priv_note")

            .columns
              .column.is-4
                .field
                  label.label Top Standing
                .field
                  .control
                    b-select(v-model="all_standings[props.row.id].p1" expanded)
                      option(value="0") -
                      option(v-for="r in filteredRolls(props.row.id)" :value="r.member") {{membersById[r.member].nickname}}

              .column.is-4
                .field
                  label.label Second
                .field
                  .control
                    b-select(v-model="all_standings[props.row.id].p2" expanded)
                      option(value="0") -
                      option(v-for="r in filteredRolls(props.row.id)" :value="r.member") {{membersById[r.member].nickname}}


              .column.is-4
                .field
                  label.label Third
                .field
                  .control
                    b-select(v-model="all_standings[props.row.id].p3" expanded)
                      option(value="0") -
                      option(v-for="r in filteredRolls(props.row.id)" :value="r.member") {{membersById[r.member].nickname}}

            .field.is-grouped.is-grouped-centered
              .control
                button.button.is-danger(@click="saveStanding(props.row.id)" :disabled="saveButton(props.row.id).disabled" :class="saveButton(props.row.id).classes") SAVE STANDINGS

            .field
              label.label Raider Rolls
            table.table.is-bordered.is-fullwidth
              thead
                tr
                  th Member
                  th Roll
                  th Note
                  th Last update
              tbody
                tr(v-for="r in filteredRolls(props.row.id)")
                  td {{membersById[r.member].nickname}}
                  td {{r.roll}}
                  td {{r.note}}
                  td {{toDate(r.updated)}}

            br
            .field
              label.label Previous awards
            table.table.is-bordered.is-fullwidth
              thead
                tr
                  th Member
                  th Roll
                  th Note
                  th Awarded
              tbody
                tr(v-for="r in awardedRolls(props.row.id)")
                  td {{membersById[r.member].nickname}}
                  td {{r.roll}}
                  td {{r.note}}
                  td {{toDate(r.updated)}}

</template>

<style scoped>
.loader-box {
    display: grid;
    place-items: center;
}

.loader {
    width: 50px;
    height: 50px;
}

.pad-right {
    padding-right: 0.5rem;
}

</style>

<script>
export default {
    name: "Council",
    data () {
        return {
            loading: true,
            selected_boss: 'all',
            standings: [],
            raider_rolls: [],
            raid_members: [],
            all_standings: {},
            all_awards: {},

            all_rolls: {},
            saving_standing: false,
            saving_standing_id: null,

            saving_award: false,
            saving_award_id: null,
        }
    },
    props: ['memberID'],
    mounted: async function () {
        // Load member rolls
        if (this.loading == true) {
            await this.loadRolls();
        }
    },
    methods: {
        loadRolls: async function () {
            this.loading = true;
            let council_data = await this.$api.generic('get', `/team/${this.$user.active_raid_team.raid_team}/council_data`);
            this.standings = council_data.standings;
            this.raider_rolls = council_data.rolls;
            this.raid_members = council_data.members;
            // Generate dummy standings for all items
            for (let o of this.$user.loot.items) {
                this.all_awards[o.id] = {
                    member: null,
                    note: null,
                };
                let st = this.standings.find(r => r.item == o.id);
                if (st) {
                    this.all_standings[o.id] = {
                        pub_note: st.pub_note,
                        priv_note: st.priv_note,
                        p1: st.p1,
                        p2: st.p2,
                        p3: st.p3,
                        updated: st.updated,
                    }
                } else {
                    this.all_standings[o.id] = {
                        pub_note: null,
                        priv_note: null,
                        p1: null,
                        p2: null,
                        p3: null,
                        updated: null,
                    }
                }
            }

            console.log(council_data);
            this.loading = false;
        },
        changeBoss: function (i) {
            this.selected_boss = i;
        },
        filteredBosses: function (lz) {
            return this.$user.loot.bosses.filter(b => b.zone == lz);
        },
        itemSubloot: function (i) {
            return this.$user.loot.subloot.filter(s => s.subloot_of == i);
        },
        toDate: function (d) {
            return (new Date(d)).toLocaleDateString();
        },
        awardItem: async function (i) {
            if (this.saving_award) return;
            this.saving_award_id = i;
            this.saving_award = true;
            try {
                await this.$api.generic('post', `/team/${this.$user.active_raid_team.raid_team}/award`, {
                    item: i,
                    ...this.all_awards[i],
                });

                await this.loadRolls();

                this.$buefy.toast.open({
                    message: "Item awarded!",
                    type: 'is-success'
                });
                // Does standings need to be updated here???
            } catch (e) {
                this.$log.error("Failed to award item");
                this.displayError(e);
            }
            this.saving_award = false;
        },
        awardButton: function (i) {
            return {
                disabled: this.saving_award && this.saving_award_id != i,
                classes: {
                    'is-loading': this.saving_award && this.saving_award_id == i,
                }
            }
        },
        saveStanding: async function (i) {
            if (this.saving_standing) return;
            this.saving_standing_id = i;
            this.saving_standing = true;
            try {
                await this.$api.generic('put', `/team/${this.$user.active_raid_team.raid_team}/standing`, {
                    item: i,
                    ...this.all_standings[i],
                });

                this.$buefy.toast.open({
                    message: "Standing saved!",
                    type: 'is-success'
                });
                // Does standings need to be updated here???
            } catch (e) {
                this.$log.error("Failed to update standing");
                this.displayError(e);
            }
            this.saving_standing = false;
        },
        saveButton: function (i) {
            return {
                disabled: this.saving_standing && this.saving_standing_id != i,
                classes: {
                    'is-loading': this.saving_standing && this.saving_standing_id == i,
                }
            }
        },
        filteredRolls: function (i) {
            return this.raider_rolls.filter(x => x.item == i && x.awarded == false);
        },
        awardedRolls: function (i) {
            return this.raider_rolls.filter(x => x.item == i && x.awarded == true);
        },
    },
    computed: {
        selectedLoot: function () {
            if (this.selected_boss == 'all') {
                return {
                    title: "All Loot",
                    items: this.$user.loot.items.slice().sort((a, b) => a.name > b.name ? 1 : -1),
                }
            } else {
                let b = this.$user.loot.bosses.find(b => b.id == this.selected_boss);
                let items = this.$user.loot.combos.filter(x => x.boss == this.selected_boss).map(y => y.item);

                return {
                    title: `${b.name} Loot`,
                    items: this.$user.loot.items.slice().filter(i => items.includes(i.id)).sort((a, b) => a.name > b.name ? 1 : -1),
                };
            }
        },
        membersById: function () {
            let memberMap = {};
            for (let m of this.raid_members) {
                memberMap[m.id] = m;
            }
            return memberMap;
        }
    }
}
</script>
