<template lang="pug">
.container.growToPage
  .loader-box(v-if="loading")
    p.has-text-weight-bold Loading standings...
    .loader

  .columns(v-else)
    .column.is-3
      b-menu
        b-menu-list(label="All Loot")
          b-menu-item(@click="changeBoss('all')" label="All items")
          b-menu-item(@click="changeBoss('rolled')" label="Items I've rolled on" :active="true")
        b-menu-list(v-for="lz in $user.loot.zones" :label="lz.name")
          b-menu-item(v-for="b in filteredBosses(lz.id)" @click="changeBoss(b.id)" :label="b.name")


    .column.is-9
      .box(v-if="selected_boss == null")
        p.has-text-centered No loot selected
      .box(v-else)
        .level
          .level-left
            .level-item
              .title.is-4 {{selectedLoot.title}}
        hr

        b-table(:data="selectedLoot.items" :bordered="true")
          b-table-column(label="Item" v-slot="props")
            a.q4.wowhead-link(:href="`https://classic.wowhead.com/item=${props.row.id}`" target="_blank") [{{props.row.name}}]
          b-table-column(label="Top Standing" v-slot="props")
            span {{all_standings[props.row.id].p1 == null ||  all_standings[props.row.id].p1 == 0 ? 'Unassigned' : all_standings[props.row.id].p1}}
          b-table-column(label="Second" v-slot="props")
            span {{all_standings[props.row.id].p2 == null ||  all_standings[props.row.id].p2 == 0  ? 'Unassigned' : all_standings[props.row.id].p2}}
          b-table-column(label="Third" v-slot="props")
            span {{all_standings[props.row.id].p3 == null ||  all_standings[props.row.id].p3 == 0  ? 'Unassigned' : all_standings[props.row.id].p3}}
          b-table-column(label="Note" v-slot="props")
            span {{all_standings[props.row.id].pub_note == null ? 'Not Set' : all_standings[props.row.id].pub_note}}

          b-table-column(label="Updated" v-slot="props")
            span {{ all_standings[props.row.id].updated == null ? 'Never' : toDate(all_standings[props.row.id].updated) }}


</template>

<style scoped>
.loader-box {
    display: grid;
    place-items: center;
}

.loader {
    width: 50px;
    height: 50px;
}

.pad-right {
    padding-right: 0.5rem;
}

</style>

<script>
export default {
    name: "Standings",
    data () {
        return {
            loading: true,
            selected_boss: 'rolled',
            standings: [],
            member_rolls: [],
            all_standings: {},
        }
    },
    props: ['memberID'],
    mounted: async function () {
        // Load standings
        if (this.loading == true) {
            await this.loadStandings();
        }
    },
    methods: {
        loadStandings: async function () {
            this.loading = true;
            let standingsInfo = await this.$api.generic('get', `/team/${this.$user.active_raid_team.raid_team}/standings/${this.memberID}`);
            this.standings = standingsInfo.standings;
            this.member_rolls = standingsInfo.rolls;

            console.log(this.standings);

            for (let o of this.$user.loot.items) {
                let st = this.standings.find(r => r.item == o.id);
                if (st) {
                    this.all_standings[o.id] = st;
                } else {
                    this.all_standings[o.id] = {
                        pub_note: null,
                        p1: null,
                        p2: null,
                        p3: null,
                        updated: null,
                    }
                }
            }
            this.loading = false;
        },
        changeBoss: function (i) {
            this.selected_boss = i;
        },
        filteredBosses: function (lz) {
            return this.$user.loot.bosses.filter(b => b.zone == lz);
        },
        itemSubloot: function (i) {
            return this.$user.loot.subloot.filter(s => s.subloot_of == i);
        },
        toDate: function (d) {
            return (new Date(d)).toLocaleDateString();
        },
    },
    computed: {
        selectedLoot: function () {
            if (this.selected_boss == 'all') {
                return {
                    title: "All Loot",
                    items: this.$user.loot.items.slice().sort((a, b) => a.name > b.name ? 1 : -1),
                }
            } else if (this.selected_boss == 'rolled') {
                let rolled_items = this.member_rolls.filter(i => i.awarded != true && i.roll != 'pass').map(r => r.item);
                return {
                    title: "Rolled Items",
                    items: this.$user.loot.items.slice().filter(i => rolled_items.includes(i.id)).sort((a, b) => a.name > b.name ? 1 : -1),
                };
            } else {
                let b = this.$user.loot.bosses.find(b => b.id == this.selected_boss);
                let items = this.$user.loot.combos.filter(x => x.boss == this.selected_boss).map(y => y.item);

                return {
                    title: `${b.name} Loot`,
                    items: this.$user.loot.items.slice().filter(i => items.includes(i.id)).sort((a, b) => a.name > b.name ? 1 : -1),
                };
            }
        },
    }
}
</script>
