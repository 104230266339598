<template lang="pug">
.container.growToPage
  .headpad
    h3.is-size-2.has-text-centered Loot Manager

  .field.has-text-centered(v-if="load_error")
    p.has-text-weight-bold.has-text-danger LOAD FAILED

  .loader-box(v-else-if="loading || !$user.loaded_loot")
    p.has-text-weight-bold Loading team data...
    .loader

  div(v-else)
    .field.has-text-centered
      .control.padbot
        .subtitle {{`${$user.active_raid_team.team_name} - ${$user.active_raid_team.nickname || 'Please set your character name under "Info"'}`}}

    section
      b-tabs(type="is-boxed" v-model="active_tab" @input="changeTab" expanded)
        b-tab-item(v-for="t in tabs" :label="t.title" :icon="t.icon")

    component(:is="tabs[active_tab].component" :memberID="$route.params.id")


</template>

<style scoped>
.loader-box {
    display: grid;
    place-items: center;
}

.loader {
    width: 50px;
    height: 50px;
}

.headpad {
    padding: 1rem;
}

.padbot {
    padding-bottom: 1rem;
}

</style>

<script>
import MemberInfo from "./MemberInfo";
import MemberRolls from "./MemberRolls";
import Council from "./Council";
import Standings from "./Standings";
import LootHistory from "./LootHistory";


export default {
    name: "TeamMember",
    data () {
        return {
            load_error: false,
            loading: true,
            active_tab: 0,
        }
    },
    components: {
        MemberInfo,
        MemberRolls,
        Council,
        Standings,
        LootHistory,
    },
    activated: async function () {
        this.active_tab = 1;
        if (this.$route.params.id != this.$user.active_raid_team.id) {
            this.loading = true;
        }

        this.$log.debug('team member activated');
        await this.checkTeamValid();

        let hashstring = this.$route.hash.slice(1);
        let t = this.tabs.findIndex(x => x.component == hashstring);
        if (t >= 0) {
            this.active_tab = t;
        }
    },
    watch: {
        '$user.loaded_teams': function () {
            this.$log.debug(`load teams changed: ${this.$user.loaded_teams}`);
            this.checkTeamValid();
        },
        '$route.hash': function () {
            let hashstring = this.$route.hash.slice(1);
            this.$log.debug(`hash changed ${hashstring}`);
            if (this.tabs[this.active_tab].component != hashstring) {
                let t = this.tabs.findIndex(x => x.component == hashstring);
                if (t >= 0) {
                    this.active_tab = t;
                }
            }
        }
    },
    methods: {
        checkTeamValid: async function () {
            if (this.$user.loaded_teams) {
                if (this.$route.params.id != this.$user.active_raid_team.id) {
                    try {
                        await this.$user.changeTeam(this.$route.params.id);
                        this.loading = false;
                    } catch (e) {
                        this.$buefy.dialog.alert({
                            title: `Error!`,
                            message: `${e.message}`,
                            type: 'is-danger',
                            hasIcon: true,
                        });
                        this.load_error = true;
                    }
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = true;
            }
        },
        isActiveTab: function (t) {
            return {
                'is-active': t == this.active_tab,
            }
        },
        changeTab: function (t) {
            console.log(`Changed tab ${t}`);
            window.location = `#${this.tabs[t].component}`
        }

    },
    computed: {
      tabs: function () {
          let baseTabs = [{
              title: "Standings",
              icon: "trophy-alt",
              component: 'Standings',
          }, {
              title: "My Rolls",
              icon: "dice",
              component: "MemberRolls",
          }, {
              title: "Loot History",
              icon: "history",
              component: 'LootHistory',
          }, {
              title: "Info",
              icon: "cog",
              component: "MemberInfo"
          }];

          if (this.$user.active_raid_team.is_council_member) {
              baseTabs.splice(2, 0, {
                  title: "Council Management",
                  icon: "users",
                  component: "Council",
              });
          }

          return baseTabs;
      }
    }
}
</script>
