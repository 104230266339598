class UserStore {
    constructor(Vue, options) {
        // Check for existing token in localstorage
        let localToken = localStorage.getItem('user-jwt') || '';

        // Lets make a new instance to store the data
        this.storeVM = new Vue({
            data() {
                return {
                    token: localToken === '' ? null : localToken,
                    loaded_teams: false,
                    raid_teams: [],
                    active_raid_team: {},
                    loaded_loot: false,
                    loot: {},
                };
            },
        });
    }

    get is_authenticated() {
        return this.storeVM.$data.token != null;
    }

    get info() {
        if (this.storeVM.$data.token != null) {
            return JSON.parse(atob(this.storeVM.$data.token.split('.')[1]));
        }
        return null;
    }

    get token() {
        return this.storeVM.$data.token;
    }

    set token(t) {
        this.storeVM.$data.token = t;
    }

    set raid_teams(rts) {
        this.storeVM.$data.raid_teams = rts;
        this.storeVM.$data.loaded_teams = true;
    }

    get raid_teams() {
        return this.storeVM.$data.raid_teams;
    }

    get active_raid_team() {
        return this.storeVM.$data.active_raid_team;
    }

    get loaded_teams() {
        return this.storeVM.$data.loaded_teams;
    }

    resetTeams () {
        this.storeVM.$data.loaded_teams = false;
        this.storeVM.$data.active_raid_team = {};
        this.storeVM.$data.raid_teams = [];
    }

    resetLoot () {
        this.storeVM.$data.loaded_loot = false;
        this.storeVM.$data.loot = {};
    }

    set loot(l) {
        this.storeVM.$data.loot = l;
        this.storeVM.$data.loaded_loot = true;
    }

    get loaded_loot() {
        return this.storeVM.$data.loaded_loot;
    }

    get loot() {
        return this.storeVM.$data.loot;
    }



    async changeTeam (memberID) {
        let t = this.storeVM.$data.raid_teams.find(x => x.id == memberID);

        if (t != null) {
            this.storeVM.$data.active_raid_team = JSON.parse(JSON.stringify(t));
        } else {
            throw {message: "Invalid Team"}
        }
    }

}


export default {
    /**
     * Install plugin
     * @param {Vue} Vue - Vue instance
     * @param {Object} options - Options for the plugin
     */
    install: (Vue, options = {}) => {
        let d = new UserStore(Vue, options);
        Vue.user = d;
        Vue.prototype.$user = d;
    },
};

// Usage: import UserStore from 'plugins/store'; Vue.use(UserStore);
