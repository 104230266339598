<template lang="pug">
.box.is-fullwidth

  .title.has-text-centered Your Info

  .field
    label.label Character name (for this raid team)
  .field
    .control
      input.input(type="text" v-model="member_info.nickname")

  .field
    label.label Class/Spec Note
  .field
    .control
      input.input(type="text" v-model="member_info.spec")

  .field.is-grouped.is-grouped-centered
    .control
      button.button.is-danger(@click="updateMemberInfo" :class="updateButton.classes") UPDATE

  hr

  .title.has-text-centered {{$user.active_raid_team.team_name}} Info

  .field
    label.label Team Notes
  .field
    .control
      textarea.textarea.is-static(readonly rows="8" :value="$user.active_raid_team.team_notes")




</template>

<style scoped>
.loader-box {
    display: grid;
    place-items: center;
}

.loader {
    width: 50px;
    height: 50px;
}

.headpad {
    padding: 1rem;
}

.padbot {
    padding-bottom: 1rem;
}

</style>

<script>
export default {
    name: "RaidAdmin",
    data () {
        return {
            member_info: {
                nickname: "",
                spec: "",
            },
            updating_member: false,
        }
    },
    mounted: async function () {
        this.member_info.nickname = this.$user.active_raid_team.nickname;
        this.member_info.spec = this.$user.active_raid_team.spec;

        console.log(this.$user.active_raid_team);

        console.log("info mounted");
    },
    methods: {
        updateMemberInfo: async function () {
            if (this.updating_member) return;

            this.updating_member = true;
            try {
                await this.$api.generic('put', `/member/${this.$user.active_raid_team.id}`, this.member_info);
                await this.$api.load_raid_teams();
            } catch (e) {
                this.$buefy.dialog.alert({
                    title: `Error!`,
                    message: `${e.response.data.err || e.message}`,
                    type: 'is-danger',
                    hasIcon: true,
                });

                this.$log.error("Failed to update member info");
                this.$log.error(JSON.stringify(e.response));
            }
            this.updating_member = false;
        }
    },
    computed: {
        updateButton: function () {
            return {
                classes: {
                    'is-loading': this.updating_member
                }
            }
        }
    }
}
</script>
